"use client";

import React from 'react';
import { useRouter } from 'next/navigation';

import { searchPopularConditions } from './config/searchPopularConditions.config';

import Typography from '../../_ui/_blocks/Typography/Typography';
import TagCloud from '../../TagCloud/TagCloud';

type SearchPopularCondition = (typeof searchPopularConditions)[number];

const SearchPopularConditions = () => {
    const router = useRouter();

    const handleClick = (tag: SearchPopularCondition) => {
        router.push(tag.url);
    };

    return (
        <section className="space-y-100 text-center">
            <Typography as="h4" typeset="subtitle" size="100" weight="600">
                Popular Conditions
            </Typography>
            <div className="max-w-[26.25rem] w-full mx-auto">
                <TagCloud color="regular" tags={searchPopularConditions} onClick={handleClick} />
            </div>
        </section>
    );
};

export default SearchPopularConditions;
